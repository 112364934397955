import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import Button from '@mui/material/Button'
import axios from '../../../api/axios'
import * as Actions from '../../../components/dashboard/store/actions'
import * as Endpoint from '../../../Endpoint'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table"
import { toggleSnackbar } from '../../../components/dashboard/store/actions'
import { Filter } from './Filter'
import ApplyLeaveDialogBox from '../ApplyLeaveDialogBox'
import moment from 'moment'

const MOCK = [
  {
    "user_id": "Desiri",
    "start_date": 1,
    "end_date": 1,
    "reason": 1,
    "status": 1,
    "approve": 1,
    "deny": 1
  },
  {
    "user_id": "Desiri",
    "start_date": 1,
    "end_date": 1,
    "reason": 1,
    "status": 1,
    "approve": 1,
    "deny": 1
  }
]


export const COLUMNS = [
  {
    Header: "Name",
    accessor: "user_id",
    maxWidth: 40,
    minWidth: 30,
    width: 32,
  },
  {
    Header: "Start Date",
    accessor: "start_date"
  },
  {
    Header: "End Date",
    accessor: "end_date"
  },
  {
    Header: "Duration (Days)",
    accessor: "days"
  },
  {
    Header: "Notes",
    accessor: "reason"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Approve",
    accessor: "approve",
  },
  {
    Header: "Deny",
    accessor: "deny",
  },
  {
    Header : "Category",
    accessor : "category",
  }
]


const LeaveTable = (props) => {
  const { email } = useParams()
  const dispatch = useDispatch()
  const [adminData, setAdminData] = useState([])
  const adminLeavesData = useSelector((leavemanagement) => leavemanagement.leavemanagementReducer.adminActiveLeaveApplicationsData)
  const is_admin_leaves_data_loading = useSelector((leavemanagement) => leavemanagement.leavemanagementReducer.isAdminActiveLeaveApplicationsDataLoading)
  const currentYear = moment().year().toString();

  useEffect(() => {
    dispatch(Actions.getAdminActiveLeaveApplications(currentYear, props.loggedInUserEmail))
  }, [])

  useEffect(() => {
    if (Object.keys(adminLeavesData).length > 0) {
      setAdminData(adminLeavesData)
    }
  }, [Object.keys(adminLeavesData).length])

  const handleLeaveApprovalAndRejection = async (cell) => {
    const leaveStatus = cell.column.Header;
    const currCell = cell.row.original

    const postData = {
      dates: currCell.dates,
      status: leaveStatus === "Approve" ? "Accepted" : "Rejected",
      reason: currCell.reason,
      user_id: currCell.user_id,
      year: currentYear,
      category : currCell.category
    }
    const headers = {
      "X-API-Key": process.env.REACT_APP_API_KEY
    };
    
    const res = await axios.post(Endpoint.API_APPLY_AND_SANCTION_LEAVE, postData,{
      headers: headers
    })
    if (res.data === "successfully called") {
      dispatch(toggleSnackbar({
        status: true,
        message: leaveStatus === "Approve" ? "Approving..." : "Rejecting...",
        severity: "success"
      }))
      dispatch(Actions.getAdminActiveLeaveApplications(currentYear, props.loggedInUserEmail))
    } else {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again later",
        severity: "error"
      }))
    }
  }

  const handleApplyLeave = async (dates, status, reason, userId, year, leaveCategory) => {

    try {
      const postData = {
        dates: dates,
        status: status,
        reason: reason,
        user_id: userId,
        year: year,
        category : leaveCategory
      }
      const headers = {
        "X-API-Key": process.env.REACT_APP_API_KEY
      };
      
      const res = await axios.post(Endpoint.API_APPLY_AND_SANCTION_LEAVE, postData,{
        headers: headers
      })
      if (res.data === "successfully called") {
        dispatch(toggleSnackbar({
          status: true,
          message: "Applying...",
          severity: "success"
        }))
        dispatch(Actions.getAdminActiveLeaveApplications(currentYear, props.loggedInUserEmail))
      }
    } catch (err) {
      dispatch(toggleSnackbar({
        status: true,
        message: "Something went wrong...Try again later",
        severity: "error",
      }))
    }
  }

  const columns = useMemo(() => COLUMNS, [COLUMNS])
  const data = useMemo(() => {
    if (Object.keys(adminLeavesData).length > 0) {
      const statusOrder = { pending: 1, Rejected: 2 , Accepted : 3};
  
      return adminLeavesData.sort((a, b) => {
        if (statusOrder[a.status] !== statusOrder[b.status]) {
          return statusOrder[a.status] - statusOrder[b.status];
        }
        return b.end_date.localeCompare(a.end_date);
      });
    }
    return MOCK;
  }, [adminLeavesData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 8 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    prepareRow,
  } = tableInstance
  
  const [currentyear,setYear] = useState(moment().year())
  const [Selectedyear ,setselectedyear ] = useState(currentyear)
  const [arrayofyears,setarrayofyears] = useState([currentyear,currentyear-1,currentyear+1])
  useEffect(() => {
    dispatch(Actions.getAdminActiveLeaveApplications(Selectedyear, props.loggedInUserEmail))
  }, [Selectedyear])
  const handleChange = (event)=>{setselectedyear(event.target.value)}

  const { pageIndex, globalFilter } = state
  return (
    <div className="admin--leavemanagement--wrapper">
      {is_admin_leaves_data_loading ? (
        <div className="kube--util--center--div">
          <div className='dot-flashing'>
          </div>
        </div>
      ) : (
        <div className="admin--leavemanagement--content">
          <FormControl fullWidth style ={{"position":"relative","left":"75%","top":"10px"}}>
                  <InputLabel id="demo-simple-select-label">Year</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Selectedyear}
                    label="Year"
                    onChange={handleChange}
                  >
                    {arrayofyears.map((item,index)=>{
                      console.log(item)
                      return(<MenuItem value={item}>{item}</MenuItem>)
                    })}
                    
                  </Select>
                </FormControl>

          <div className="admin--leavemanagement--top--section" style={{"position":"relative","top":"10px"}}>
            <Filter filter={globalFilter} setFilter={setGlobalFilter} />
            <div className="apply-leave-dialog">
              <ApplyLeaveDialogBox handleApplyLeave={handleApplyLeave} />
            </div>
          </div>

          <div className="admin--leavemanagement--hero--section">
            <table className='table' {...getTableProps()}>
              <thead className='thead'>
                {headerGroups.map((headerGroup) => (
                  <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "  ▲"
                              : "  ▼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className='tbody' {...getTableBodyProps()}>
                {page.map((row, idx) => {
                  prepareRow(row)
                  return (
                    <>
                      <tr {...row.getRowProps()} key={idx}>
                        {row.cells.map((cell) => {
                          const buttonIsDisabled = cell?.row?.original?.status === "pending" ? false : true
                          if (cell.column.Header === "Approve") {
                            return (
                              <td>
                                <Button color="success" disabled={buttonIsDisabled} onClick={() => { handleLeaveApprovalAndRejection(cell) }}>
                                  Approve
                                </Button>
                              </td>
                            )
                          } else if (cell.column.Header === "Deny") {
                            return (
                              <td>
                                <Button color="error" disabled={buttonIsDisabled} onClick={() => { handleLeaveApprovalAndRejection(cell) }}>
                                  Reject
                                </Button>
                              </td>
                            )
                          }
                          else {
                            return (
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            )
                          }
                        })}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="admin--leavemanagement--bottom--section">
            <div className="kube__userselect-pagination">
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Prev
              </button>
              <span className="kube__userselect-pagination-details">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
          </div>

        </div>
      )}
    </div>
  )
}

export default LeaveTable