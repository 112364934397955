import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as Actions from '../../../../dashboard/store/actions'
import * as Endpoint from '../../../../../Endpoint'
import axios from '../../../../../api/axios'
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table"
import { USERCOLS } from "../../../../../shared/table_columns/UserHeaders";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment'

const MOCK = [{
    "user_id": "Desiri",
    "working_hours": 1,
    "Public_Time_Off": 1,
    "Personal_Time_Off": 1,
    "Effective_hours": 1,
    "Compliance": 1,
    "intensity": 1
}, {
    "user_id": "Katey",
    "working_hours": 2,
    "Public_Time_Off": 2,
    "Personal_Time_Off": 2,
    "Effective_hours": 2,
    "Compliance": 2,
    "intensity": 2
}]

export const COLUMNS = [
    ...USERCOLS
]
const presentYear = moment().year();
const months = [];

for (let i = 0; i < 12; i++) {
    // Add months for the previous year
    months.push(`${moment().year(presentYear - 1).month(i).format("MMMM")} ${presentYear - 1}`);
}

for (let i = 0; i < 12; i++) {
    // Add months for the current year
    months.push(`${moment().year(presentYear).month(i).format("MMMM")} ${presentYear}`);
}

function BasicSelect({ month, monthName, handleChange }) {

    return (
        <Box sx={{
            minWidth: 120,
            cursor: 'pointer',
            outline: 'none',
            background: '#ECF0F3',
            boxShadow: '-8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6',
            borderRadius: '10px',
        }}>
            <FormControl fullWidth >
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                    value={month}
                    label="Month"
                    onChange={handleChange}
                    sx={{
                        cursor: 'pointer',
                        outline: 'none',
                        background: '#ECF0F3',
                        borderRadius: '10px',
                        border: 'none',
                        color: '#4B566C',
                        fontFamily: 'Inter',
                        fontWeight: 500
                    }}
                >
                    {months.map((month, index) => {
                        return <MenuItem value={index} name={month} key={index}>{month}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box >
    )
}

const MonthlyComplaince = (props) => {
    const dispatch = useDispatch()
    const { email: userEmail } = useParams()
    const [complianceData, setComplianceData] = useState([]);
    const adminComplianceData = useSelector((compliance) => compliance.complianceReducer.monthlyComplianceData)
    const isDataLoading = useSelector((compliance) => compliance.complianceReducer.isMonthlyComplianceDataLoading)

    const userJIRAComplianceData = useSelector((compliance) => compliance.complianceReducer.userJIRAComplianceData);
    const isUserJIRAComplianceDataLoading = useSelector((compliance) => compliance.complianceReducer.isUserJIRAComplianceDataLoading);

    useEffect(function () {
        let mapping = [{}];

        if (adminComplianceData.length > 0) {
            mapping[0] = {
                ...adminComplianceData[0],
                Done: userJIRAComplianceData.Done
            }
        }

        setComplianceData(mapping);
    }, [adminComplianceData, userJIRAComplianceData]);

    let currentDate = moment();
    let monthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD');
    let monthEnd = currentDate.clone().endOf('month').format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState(monthStart)
    const [endDate, setEndDate] = useState(monthEnd)

    const handleDateChange = useCallback((month,monthnames) => {
        month = month>12 ? month-12 : month
        const updatedMonth = moment().month(month);
        let startmanipulateddate = monthnames.slice(monthnames.length-4,monthnames.length) + updatedMonth.clone().startOf('month').format('-MM-DD')
        let endmanipulateddate = monthnames.slice(monthnames.length-4,monthnames.length) + updatedMonth.clone().endOf('month').format('-MM-DD')
        setStartDate(startmanipulateddate);
        setEndDate(endmanipulateddate);
    }, [])

    const currMonth = moment().year() === currentDate.year()? 12 + currentDate.month() : 0+ currentDate.month()

    const [month, setMonth] = React.useState(currMonth)
    const [monthName, setMonthName] = React.useState(months[currMonth])

    const handleChange = (event) => {
        handleDateChange(event.target.value,months[event.target.value])
        setMonth(event.target.value)
        setMonthName(months[event.target.value])
    }
    const currentYear = monthName.slice(monthName.length-4,monthName.length).toString()
    useEffect(() => {
        const getUserCountry = async () => {
            try {
                const headers = {
                    "X-API-Key": process.env.REACT_APP_API_KEY
                  };
                  
                const res = await axios.get(
                    Endpoint.API_GET_USER_ROLE,
                    {
                        params: {
                            user_id: userEmail,
                        },
                        headers:headers
                    }
                )
                window.country = res.data.Country
                dispatch(Actions.getMonthlyComplianceData(userEmail, startDate, endDate, currentYear, window.country))
                dispatch(Actions.getUserJIRAComplianceData(startDate, endDate, userEmail));
            } catch (err) {
                console.log(err)
            }
        }
        getUserCountry()
    }, [])

    useEffect(() => {
        dispatch(Actions.getMonthlyComplianceData(userEmail, startDate, endDate, currentYear, window.country));
        dispatch(Actions.getUserJIRAComplianceData(startDate, endDate, userEmail));
    }, [startDate, endDate])


    const columns = useMemo(() => (COLUMNS), [COLUMNS])
    const data = useMemo(() => {
        if (Object.keys(complianceData).length > 0) {
            return complianceData;
        } else {
            return MOCK;
        }
    }, [complianceData]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = tableInstance

    return (
        <>
            {(isDataLoading || isUserJIRAComplianceDataLoading || complianceData.length === 0) ? (
                <div className="kube__userselect-loading"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        zIndex: "100",
                        margin: "-8rem auto auto"
                    }}
                >
                    <div className='dot-flashing'></div>
                </div>
            ) : (
                    complianceData.length > 0 ? (
                    <>
                        <div>
                            <div className='monthly-compliance-select'>
                                <BasicSelect style={{ color: 'black', backgroundColor: 'green', borderRadius: '1rem', height: '3rem' }} handleChange={handleChange} month={month} monthName={monthName} />
                            </div>
                            <table className='table' {...getTableProps()}>
                                <thead className='thead' >
                                    {headerGroups.map((headerGroup) => (
                                        <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render("Header")}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody className='tbody' {...getTableBodyProps()}>
                                    {page.map((row, idx) => {
                                        prepareRow(row)
                                        return (
                                            <>
                                                <tr className='trBody' {...row.getRowProps()} key={idx}>
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td className='td' {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                                        )
                                                    }
                                                    )
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    null
                )
            )
            }
        </>
    )
}

export default MonthlyComplaince
