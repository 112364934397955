import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import * as Actions from '../../../dashboard/store/actions'
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table"
import moment from 'moment';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Filter } from '../Filter'
import { CSVLink } from "react-csv";
import { ADMINHEADERS } from "../../../../shared/table_columns/AdminHeaders";
import DataTable from './workunitsmodal'
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton,Dialog } from '@mui/material';
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";


const MOCK = [{
    "user_id": "Desiri",
    "working_hours": 1,
    "Public_Time_Off": 1,
    "Personal_Time_Off": 1,
    "Effective_hours": 1,
    "Compliance": 1,
    "intensity": 1
}, {
    "user_id": "Katey",
    "working_hours": 2,
    "Public_Time_Off": 2,
    "Personal_Time_Off": 2,
    "Effective_hours": 2,
    "Compliance": 2,
    "intensity": 2
}]

export const COLUMNS = [
    ...ADMINHEADERS
]

const presentYear = moment().year();
const months = [];

for (let i = 0; i < 12; i++) {
    // Add months for the previous year
    months.push(`${moment().year(presentYear - 1).month(i).format("MMMM")} ${presentYear - 1}`);
}

for (let i = 0; i < 12; i++) {
    // Add months for the current year
    months.push(`${moment().year(presentYear).month(i).format("MMMM")} ${presentYear}`);
}

function BasicSelect({ month, monthName, handleChange }) {

    return (
        <Box sx={{
            minWidth: 120,
            cursor: 'pointer',
            outline: 'none',
            background: '#ECF0F3',
            borderRadius: '10px',
        }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                <Select
                    value={month}
                    label="Month"
                    onChange={handleChange}
                    sx={{
                        cursor: 'pointer',
                        outline: 'none',
                        background: '#ECF0F3',
                        boxShadow: '-8px -8px 16px #ffffff, 8px 8px 16px #d1d9e6',
                        borderRadius: '10px',
                        border: 'none',
                        color: '#4B566C',
                        fontFamily: 'Inter',
                        fontWeight: 500
                    }}
                >
                    {months.map((month, index) => {
                        return <MenuItem value={index} name={month} key={index}>{month}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

const MonthlyUsersComplaince = (props) => {
    const dispatch = useDispatch()
    const { email: userEmail } = useParams()
    const [loading, setLoading] = useState(true)
    const [complianceData, setComplianceData] = useState([])
    const [isvisible,setvisiblity] = useState(false)
    const [workunitdata,setworkunitsdata] = useState({})
    const adminComplianceData = useSelector((compliance) => compliance.complianceReducer.adminMonthlyComplianceData)
    const isDataLoading = useSelector((compliance) => compliance.complianceReducer.isAdminMonthlyComplianceDataLoading)

    const allUsersJIRAComplianceData = useSelector((compliance) => compliance.complianceReducer.allUsersJIRAComplianceData);
    const isAllUsersJIRAComplianceDataLoading = useSelector((compliance) => compliance.complianceReducer.isAllUsersJIRAComplianceDataLoading);

    useEffect(() => {
        let mapping;

        if (adminComplianceData.length > 0) {
            mapping = adminComplianceData?.map(element => {
                return {
                    ...element,
                    ...allUsersJIRAComplianceData[element.user_id]
                }
            });
            setComplianceData(mapping);
        }

    }, [adminComplianceData, allUsersJIRAComplianceData]);


    let currentDate = moment();
    let monthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD');
    let monthEnd = currentDate.clone().endOf('month').format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState(monthStart)
    const [endDate, setEndDate] = useState(monthEnd)

    const handleDateChange = useCallback((month,monthnames) => {
        month = month>12 ? month-12 : month
        const updatedMonth = moment().month(month);
        let startmanipulateddate = monthnames.slice(monthnames.length-4,monthnames.length) + updatedMonth.clone().startOf('month').format('-MM-DD')
        let endmanipulateddate = monthnames.slice(monthnames.length-4,monthnames.length) + updatedMonth.clone().endOf('month').format('-MM-DD')
        setStartDate(startmanipulateddate);
        setEndDate(endmanipulateddate);
    }, [])


    //setting the initital monthname
    const currMonth = moment().year() === currentDate.year()? 12 + currentDate.month() : 0+ currentDate.month()

    const [month, setMonth] = React.useState(currMonth)
    const [monthName, setMonthName] = React.useState(months[currMonth])

    const handleChange = (event) => {
        handleDateChange(event.target.value,months[event.target.value])
        setMonth(event.target.value)
        setMonthName(months[event.target.value])
    }
    const currentYear = monthName.slice(monthName.length-4,monthName.length).toString()

    useEffect(() => {
        dispatch(Actions.getAdminMonthlyComplianceData(startDate, endDate, currentYear, props.loggedInUserEmail))
        dispatch(Actions.getAllUsersJIRAComplianceData(startDate, endDate, false));
    }, [startDate, endDate])

    useEffect(() => {
        if (Object.keys(complianceData).length > 0) {
            setComplianceData(complianceData)
            setLoading(false)
        }
    }, [Object.keys(complianceData).length])

    const columns = useMemo(() => (COLUMNS), [COLUMNS])
    const data = useMemo(() => {
        if (Object.keys(adminComplianceData).length > 0) {
            return complianceData;
        } else {
            return MOCK
        }
    }, [complianceData])

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state,
        setGlobalFilter,
        prepareRow,
    } = tableInstance

    const { pageIndex, globalFilter, pageSize } = state

    return (
        <div className="admin--leavemanagement--wrapper">
            {(isDataLoading || isAllUsersJIRAComplianceDataLoading || complianceData.length === 0) ? (
                <div className="kube--util--center--div">
                    <div className='dot-flashing'>
                    </div>
                </div>
            ) : (
                <div className="admin--leavemanagement--content">

                    <div className="admin--leavemanagement--top--section">
                        <BasicSelect handleChange={handleChange} month={month} monthName={monthName} />
                        <Filter filter={globalFilter} setFilter={setGlobalFilter} />
                        <div className="apply-leave-dialog">
                            <CSVLink data={complianceData} filename={"Weekly_compliance"}>
                                <button className="export--csv--button">
                                    Export CSV
                                </button>
                            </CSVLink>
                        </div>
                    </div>

                    <div className="admin--leavemanagement--hero--section">
                        <table className='table' {...getTableProps()}>
                            <thead className='thead'>
                                {headerGroups.map((headerGroup) => (
                                    <tr className='trHead' {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th className='th' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className='tbody' {...getTableBodyProps()}>
                                {page.map((row, idx) => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr className='trBody' {...row.getRowProps()} key={idx}>
                                                {row.cells.map((cell) => {
                                                    if (cell.column.Header === "Work Units") {
                                                        return (
                                                          <>
                                                            <td
                                                              {...cell.getCellProps()}
                                                            >
                                                              <div style={{"display":"grid","grid-template-columns": "repeat(2,50%)","text-align":"center","justify-items":"space-between"}}>
                                                              <span style={{"width":"10px","margin":"0px","padding":"10px","textAlign":"center",}}>{cell.value}</span>
                                                              <Tooltip title="Jira Tickets" arrow>
                                                              <IconButton color="info" aria-label="info"  onClick={()=>{
                                                                setvisiblity(true)
                                                                setworkunitsdata(complianceData[cell.row.id])
                                                              }}><InfoIcon /></IconButton>
                                                              </Tooltip>
                                                            </div> 
                                                               
                                                            </td>
                                                          </>
                                                        )
                                                      }
                                                    else{
                                                        return (
                                                            <td className='td' {...cell.getCellProps({
                                                                style: {
                                                                    minWidth: cell.column.minWidth,
                                                                    width: cell.column.width,
                                                                },
                                                            })}>{cell.render("Cell")}</td>
                                                        )
                                                    }
                                                }
                                                )
                                                }
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <Dialog open = {isvisible} fullWidth ={true} maxWidth="lg" onClose = {()=>setvisiblity(false)} >
                    <DialogTitle>
                        <IconButton  aria-label="close" onClick={()=>setvisiblity(false)} style = {{"position":"relative","left":"90%"}}>
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                        <DataTable  Tasks ={workunitdata.Details}/>
                    </Dialog>

                    <div className="admin--leavemanagement--bottom--section">
                        <div className="kube__userselect-pagination">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                Prev
                            </button>
                            <span className="kube__userselect-pagination-details">
                                <span>
                                    Page{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                            </span>
                            <select value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}>
                                {[5, 10, 15, 20, 25].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                Next
                            </button>
                        </div>
                    </div>

                </div>
            )}
        </div>
    )
}

export default MonthlyUsersComplaince