import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Radio from '@mui/material/Radio';
import { Typography } from '@material-ui/core'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from "react-datepicker"
import { toggleSnackbar } from '../dashboard/store/actions'
import { useDispatch } from 'react-redux'
import * as Endpoint from '../../Endpoint'
import axios from 'axios';

export default function ApplyLeaveDialogBox(props) {
    const dispatch = useDispatch()
    const {email : userEmail} = useParams()
    const [reasonForLeave, setReasonForLeave] = useState("")
    const [startDate, setStartDate] = useState(new Date().getTime() - 518400000)
    const [endDate, setEndDate] = useState(new Date().getTime() - 518400000)
    const [leaveCategory, setLeaveCategory] = useState("")
    const [open, setOpen] = useState(false)
    const currYear = new Date().getFullYear().toString()

    const [sickLeavesRemaining, setSickLeavesRemaining] = useState(1);
    const [privilegedLeavesRemaining, setPrivilegedLeavesRemaining] = useState(0);
    const [leaveData, setLeaveData] = useState([]); 

    async function fetchDetails() {
        try {
            const currentYear = new Date().getFullYear();
            const apiUrl = `${Endpoint.API_USER_ACTIVE_LEAVE_APPLICATIONS}?user_id=${userEmail}&year=${currentYear}`;
            
            const response = await axios.get(apiUrl, {
                headers: {
                    'X-API-Key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json',
                },
            });

            const leaveSummary = response.data.leaveSummary;
            const sickLeaveCount = leaveSummary.find(item => item.category === 'sick')?.count || 0;
            const privilegedLeaveCount = leaveSummary.find(item => item.category === 'privileged')?.count || 0;

            // Update remaining leave counts
            setSickLeavesRemaining(10 - sickLeaveCount); // 10 sick leaves available
            setPrivilegedLeavesRemaining(15 - privilegedLeaveCount); // 15 privileged leaves available

            setLeaveData(response.data.leaveData);

            return response.data.leaveData;
        } catch (error) {
            console.error('Error in fetchDetails:', error);
            throw error;
        }
    }

    const handleClickOpen = async () => {
        try {
            setOpen(true);
            const data = await fetchDetails();
        } catch (error) {
            console.error('Error in handleClickOpen:', error);
           
        }
    };
    
    const handleClose = () => {
        setOpen(false)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date)
    }
    const handleEndDateChange = (date) => {
        setEndDate(date)
    }

    const handleReasonChange = (event) => {
        setReasonForLeave(event.target.value)
    }

    const handleCategoryChange = (event) => {
        setLeaveCategory(event.target.value);
    }
    
    const convertDate = (dateInWords) => {
        let processDate = new Date(dateInWords)
        let currYear = processDate.getFullYear()
        let currMonth = parseInt(processDate.getMonth()) + 1
        if (Math.floor(currMonth / 10) === 0) {
            currMonth = '0' + currMonth
        }
        let currDay = processDate.getDate()
        if (Math.floor(currDay / 10) === 0) {
            currDay = '0' + currDay
        }

        return currYear + "-" + currMonth + "-" + currDay;
    }

    const getDaysArray = function (start, end) {
        let arr = []
        for (let date = new Date(start); date <= new Date(end); date.setDate(date.getDate() + 1)) {
            arr.push(convertDate(new Date(date)))
        }
       return arr
    }
    const countWorkingDays = (dates) => {
        return dates.filter(date => {
            const day = new Date(date).getDay(); // Get the day of the week (0 for Sunday, 6 for Saturday)
            return day !== 0 && day !== 6;      // Exclude Sundays (0) and Saturdays (6)
        }).length;
    };
    

    const handleSubmit = () => {
        const dates = getDaysArray(startDate, endDate);
        const status = "pending";
        const reason = reasonForLeave;
        const userId = userEmail;
        const year = currYear;
        const category = leaveCategory;
        if (reason.length === 0 || category.length === 0) {
            const message = reason.length === 0 
                ? "Please enter a reason for taking leave" 
                : "Please choose a category";
                
            dispatch(toggleSnackbar({
                status: true,
                message: message,
                severity: "error"
            }));
            handleClose();
            return;
        }
        const remainingLeaves = category === "sick" 
            ? sickLeavesRemaining 
            : category === "privileged" 
            ? privilegedLeavesRemaining 
            : 0;
    
        const workingDays = countWorkingDays(dates);

        if (workingDays > remainingLeaves && leaveCategory !== "paid") {
            dispatch(toggleSnackbar({
                status: true,
                message: `You have only ${remainingLeaves} ${category} leaves remaining.`,
                severity: "error"
            }));
            handleClose();
            return;
        }
        props.handleApplyLeave(dates, status, reason, userId, year, category);
        handleClose();
    };
    
    const isPaidLeaveDisabled = sickLeavesRemaining > 0 || privilegedLeavesRemaining > 0;
    

    return (
        <div>
            <div >
                <button className="user-apply-leave-button util--btn" onClick={handleClickOpen}>
                    Apply For Leave
                </button>
            </div>
            <Dialog className="leave-dialog-box" open={open} onClose={handleClose}>
                <div className="user-apply-leave-dialog">
                    <h4 style={{ color: '#4B566C' }}>
                        NOTE: If the leave is for a single day, please keep the start and end date same.
                    </h4>
                    <DialogContent>
                        <div className="leave-dialog-datepickers">
                            <DatePicker fullWidth selected={startDate} onChange={handleStartDateChange} />
                            <DatePicker fullWidth selected={endDate} onChange={handleEndDateChange} minDate={startDate}  />
                        </div>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Enter the reason for taking leave"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleReasonChange}
                        />
                        <h4 style={{ color: '#4B566C', marginTop: '20px' }}>Category</h4>
                        <RadioGroup
                            row
                            aria-labelledby="leave-category-group-label"
                            defaultValue="sick"
                            name="leave-category-group"
                            value={leaveCategory}
                            onChange={handleCategoryChange}
                            style={{ marginTop: '10px', color: '#4B566C' }}
                        >
                            <FormControlLabel
                                value="sick"
                                control={<Radio />}
                                disabled={sickLeavesRemaining === 0}
                                label={<Typography variant="body1" sx={{ fontWeight: 'bold' }}> Sick Leave ({sickLeavesRemaining>0?sickLeavesRemaining:0})</Typography>}
                                sx={{
                                    opacity: sickLeavesRemaining === 0 ? 0.5 : 1,  
                                    filter: sickLeavesRemaining === 0 ? 'blur(1px)' : 'none', 
                                }}
                            />
                            <FormControlLabel
                                value="privileged"
                                control={<Radio />}
                                label={<Typography variant="body1" sx={{ fontWeight: 'bold' }}>Privileged Leave({privilegedLeavesRemaining>0?privilegedLeavesRemaining:0})</Typography>}
                                disabled={privilegedLeavesRemaining === 0} 
                                sx={{
                                    opacity: privilegedLeavesRemaining === 0 ? 0.5 : 1,  
                                    filter: privilegedLeavesRemaining === 0 ? 'blur(1px)' : 'none', 
                                }}
                            />
                            <FormControlLabel
                                value="paid"
                                control={<Radio />}
                                label={<Typography variant="body1" sx={{ fontWeight: 'bold' }}>Paid Leave</Typography>}
                                disabled={isPaidLeaveDisabled} 
                                sx={{
                                    opacity: isPaidLeaveDisabled ? 0.5 : 1, 
                                    filter: isPaidLeaveDisabled ? 'blur(1px)' : 'none', 
                                }}
                            />
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{color : '#f3795e'}} onClick={handleClose}>Cancel</Button>
                        <Button sx={{ color: '#f3795e' }} onClick={handleSubmit}>Apply</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}
