import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { toggleSnackbar } from "../../dashboard/store/actions";
import ApplyLeaveDialogBox from "../ApplyLeaveDialogBox";
import * as Actions from "../../dashboard/store/actions";
import axios from "../../../api/axios";
import * as Endpoint from "../../../Endpoint";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

export const COLUMNS = [
  { Header: "Start Date", accessor: "start_date" },
  { Header: "End Date", accessor: "end_date" },
  { Header: "Notes", accessor: "reason" },
  { Header: "Status", accessor: "status" },
  { Header: "Category", accessor: "category" },
];

const UserLeaveManagement = () => {
  const { email: userEmail } = useParams();
  const dispatch = useDispatch();
  const [leaveData, setLeaveData] = useState([]);
  const userLeavesData = useSelector(
    (state) => state.leavemanagementReducer.userActiveLeaveApplicationsData
  );
  const currentYear = new Date().getFullYear().toString();
  const [loading, setLoading] = useState(false);
  const [sickLeaveCount, setSickLeaveCount] = useState(1);
  const [privilegedLeaveCount, setPrivilegedLeaveCount] = useState(1);

  const [paidLeaveCount, setPaidLeaveCount] = useState(1);
  const [selectedLeaveType, setSelectedLeaveType] = useState("sick");

  useEffect(() => {
    dispatch(Actions.getUserActiveLeaveApplications(userEmail, currentYear));
  }, [dispatch, userEmail, currentYear]);

  useEffect(() => {
    if (Object.keys(userLeavesData).length > 0) {
      const { leaveData, leaveSummary } = userLeavesData;

      // Sort leaveData by startDate
      const sortedLeaveData = [...leaveData].sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date)
      );
      setLeaveData(sortedLeaveData);

      const sickLeave =
        leaveSummary.find((summary) => summary.category === "sick")?.count || 0;
      const privilegedLeave =
        leaveSummary.find((summary) => summary.category === "privileged")
          ?.count || 0;
      const paidLeave =
        leaveSummary.find((summary) => summary.category === "paid")?.count || 0;
      setSickLeaveCount(sickLeave);
      setPrivilegedLeaveCount(privilegedLeave);
      setPaidLeaveCount(paidLeave);
    }
  }, [userLeavesData]);

  const handleApplyLeave = async (
    dates,
    status,
    reason,
    userId,
    year,
    category
  ) => {
    const postData = {
      dates,
      status,
      reason,
      user_id: userId,
      year,
      category,
    };

    const headers = {
      "X-API-Key": process.env.REACT_APP_API_KEY,
    };

    try {
      await axios.post(Endpoint.API_APPLY_AND_SANCTION_LEAVE, postData, {
        headers,
      });

      dispatch(
        toggleSnackbar({
          status: true,
          message: "Leave applied successfully.",
          severity: "success",
        })
      );

      dispatch(Actions.getUserActiveLeaveApplications(userEmail, currentYear));
    } catch (error) {
      console.error("Error applying leave:", error);
    }
  };

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => leaveData, [leaveData]);

  const getLeaveCount = () => {
    switch (selectedLeaveType) {
      case "sick":
        return 10 - sickLeaveCount;
      case "privileged":
        return 15 - privilegedLeaveCount;
      case "paid":
        return paidLeaveCount;
      default:
        return 0;
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 8 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <div className="admin--leavemanagement--wrapper">
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div className="admin--leavemanagement--content">
          <div className="admin--leavemanagement--top--section">
            <ApplyLeaveDialogBox handleApplyLeave={handleApplyLeave} />
          </div>

          <div className="admin--leavemanagement--hero--section">
          <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "center",
        padding: "1rem",
      }}
    >
      <FormControl
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
          minWidth: 350,
          padding: "1rem",
          background: "#f9f9f9",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputLabel
          id="leave-type-label"
          style={{
            fontSize: "1rem",
            color: "#333",
            fontWeight: "300",
            marginTop: "5px"
          }}
        >
          Leave Type
        </InputLabel>
        <Select
          labelId="leave-type-label"
          id="leave-type"
          value={selectedLeaveType}
          onChange={(e) => setSelectedLeaveType(e.target.value)}
          style={{
            flex: 1,
            borderRadius: "6px",
            background: "#fff",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            padding: "0.5rem",
          }}
        >
          <MenuItem value="sick">Sick Leaves Remaining</MenuItem>
          <MenuItem value="privileged">Privileged Leaves Remaining</MenuItem>
          <MenuItem value="paid">Paid Leaves Applied</MenuItem>
        </Select>
        <div
          style={{
            minWidth: "100px",
            padding: "0.5rem 1rem",
            background: "#e3f2fd",
            borderRadius: "6px",
            color: "#1565c0",
            fontWeight: "bold",
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          {getLeaveCount()}
        </div>
      </FormControl>
    </div>

            <table className="table" {...getTableProps()}>
              <thead className="thead">
                {headerGroups.map((headerGroup) => (
                  <tr className="trHead" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="th"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "  ▲"
                              : "  ▼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="tbody" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="admin--leavemanagement--bottom--section">
            <div className="kube__userselect-pagination">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Prev
              </button>
              <span className="kube__userselect-pagination-details">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserLeaveManagement;
